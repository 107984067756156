<template>
    <div class="poster-box" id="poster-box">
        <title-nav :title="'营销海报'"/>
        <div class="poster">
            <div class="img" v-for="(img, i) in posterList" :key="i">
              <img :src="img.url" alt="" width="100%">
            </div>
        </div>
    </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'
const seo = require('../../../config/index')

export default {
  name: 'Poster',
  components: {
    TitleNav,
  },
  created(){
  },
  mounted(){
  },
  data:()=>{
    return {
      posterList: seo.config.poster,
    }
  },
  methods:{

  },
  activated(){

  }
}
</script>

<style scoped lang="scss">
.poster-box {
    min-height: calc(100vh - 5px);
    background: #fff;
    padding: 0 0 5px;
    .poster {
      margin: 20px 20px 0;
      .img {
        margin: 10px 0;
        min-height: 475px;
        background: #f3f3f3;
      }
    }
}
</style>